import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
function ViewDetail() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState([]);
  useEffect(() => {
    const stateVal = state.filter((item) => item.id === id);

    setValue(stateVal);
  }, [id, state]); //eslint-disable-line
  const goBack = () => {
    navigate("/detail", { state: state });
  };

  return (
    <div className="container">
      <div className="search__inner">
        <h1>الصرف(Morphology)</h1>
        {value?.length > 0 ? (
          value?.map((item) => (
            <table key={item.id}>
              <tbody>
                <tr>
                  <th>Title</th>
                  <th
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ padding: "5px 10px" }}>Value</span>
                    <span className="button" onClick={() => goBack()}>
                      Back
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>جِذر</th>
                  <td>{item.result.eleven}</td>
                </tr>
                <tr>
                  <th>الباب</th>
                  <td>{item.result.nine}</td>
                </tr>
                <tr>
                  <th>Form</th>
                  <td>{item.result.ten}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{item.result.eight}</td>
                </tr>
                <tr>
                  <th>اسم المفعول</th>
                  <td>{item.result.one}</td>
                </tr>
                <tr>
                  <th>أمر</th>
                  <td>{item.result.two}</td>
                </tr>
                <tr>
                  <th>اسم الفاعل</th>
                  <td>{item.result.three}</td>
                </tr>
                <tr>
                  <th>أَوزان المصدر</th>
                  <td>{item.result.four}</td>
                </tr>
                <tr>
                  <th> المصدر</th>
                  <td>{item.result.five}</td>
                </tr>
                <tr>
                  <th> مضارع</th>
                  <td>{item.result.six}</td>
                </tr>
                <tr>
                  <th> مضارع</th>
                  <td>{item.result.six}</td>
                </tr>
                <tr>
                  <th> ماضٍ</th>
                  <td>{item.result.seven}</td>
                </tr>
                <tr>
                  <th> type</th>
                  <td>{item.result.twelve}</td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <h4>No Records Found</h4>
        )}
      </div>
    </div>
  );
}

export default ViewDetail;
