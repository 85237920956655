import firebase from "firebase/compat/app";
// the below imports are option - comment out what you don't need

// import "firebase/compat/firestore";

export const clientCredentials = {
  apiKey: "AIzaSyD3VZEcmEFl1vO4b_adyIziWHXhZy7e8B4",
  authDomain: "quran-sarf.firebaseapp.com",
  projectId: "quran-sarf",
  storageBucket: "quran-sarf.appspot.com",
  messagingSenderId: "606240107094",
  appId: "1:606240107094:web:978adca51d90d0828529f7",
  measurementId: "G-XB1YJ1701X",
};
export let firebaseApp = null;
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(clientCredentials);
} else {
  firebaseApp = firebase.app();
}
export function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(clientCredentials);
    // Check that `window` is in scope for the analytics module!
    // if (typeof window !== "undefined") {
    //   // Enable analytics. https://firebase.google.com/docs/analytics/get-started
    //   if ("measurementId" in clientCredentials) {
    //     // firebase.analytics();
    //     // firebase.performance();
    //   }
    // }
  }
}
