import "./App.css";
import "./css/style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./component/Home";
import Detail from "./component/Detail";
import ViewDetail from "./component/ViewDetail";
import { initFirebase } from "./config";
initFirebase();

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sarfs" element={<Home />} />
        <Route exact path="/detail" element={<Detail />} />
        <Route exact path="/view-detail/:id" element={<ViewDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
