import React, { useEffect, useState } from "react";
import { LineWave } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../config";

function Home() {
  const [formData, setFormData] = useState({
    first: "",
    second: "",
    third: "",
  });
  const db = getFirestore(firebaseApp);
  useEffect(() => {
    query(collection(db, "data"), where("eleven", "==", "بأأ"));
  }, []);
  const { first, second, third } = formData;
  const navigate = useNavigate();
  const [result, setResutls] = useState([]);
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    let word = first + second + third;

    const querySnapshotTech = query(
      collection(db, "data"),
      where("eleven", "==", word)
    );
    const querySnapshot = await getDocs(querySnapshotTech);

    const results = querySnapshot.docs.map((doc) => ({
      result: doc.data(),
      id: doc.id,
    }));
    setResutls(results);
    setLoading(false);
    navigate("/detail", { state: results });
  };
  // ي
  return (
    <div className="container">
      <div className="search__inner">
        <h1>
          Sarf Generator <br /> جذر (Root letter)
        </h1>
        <form onSubmit={(e) => onSubmit(e)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <select
              name="first"
              value={first}
              onChange={(e) => {
                onChange(e);
              }}
            >
              <option value="0">Select </option>
              <option value="أ">أ</option>
              <option value="ا">ا</option>
              <option value="ب">ب</option>
              <option value="ت">ت</option>
              <option value="ث">ث</option>
              <option value="ج">ج</option>
              <option value="ح">ح</option>
              <option value="خ">خ</option>
              <option value="د">د</option>
              <option value="ذ">ذ</option>
              <option value="ر">ر</option>
              <option value="ز">ز</option>
              <option value="س">س</option>
              <option value="ش">ش</option>
              <option value="ص">ص</option>
              <option value="ض">ض</option>
              <option value="ط">ط</option>
              <option value="ظ">ظ</option>
              <option value="ع">ع</option>
              <option value="غ">غ</option>
              <option value="ف">ف</option>
              <option value="ق">ق</option>
              <option value="ك">ك</option>
              <option value="ل">ل</option>
              <option value="م">م</option>
              <option value="ن">ن</option>
              <option value="ه">ه</option>
              <option value="و">و</option>
              <option value="ء">ء</option>
              <option value="ی">ی</option>
              <option value="ي">ي</option>
            </select>{" "}
            <h4>حرف الاول </h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <select
              name="second"
              value={second}
              onChange={(e) => {
                onChange(e);
              }}
            >
              <option value="0">Select </option>
              <option value="أ">أ</option>
              <option value="ا">ا</option>
              <option value="ب">ب</option>
              <option value="ت">ت</option>
              <option value="ث">ث</option>
              <option value="ج">ج</option>
              <option value="ح">ح</option>
              <option value="خ">خ</option>
              <option value="د">د</option>
              <option value="ذ">ذ</option>
              <option value="ر">ر</option>
              <option value="ز">ز</option>
              <option value="س">س</option>
              <option value="ش">ش</option>
              <option value="ص">ص</option>
              <option value="ض">ض</option>
              <option value="ط">ط</option>
              <option value="ظ">ظ</option>
              <option value="ع">ع</option>
              <option value="غ">غ</option>
              <option value="ف">ف</option>
              <option value="ق">ق</option>
              <option value="ك">ك</option>
              <option value="ل">ل</option>
              <option value="م">م</option>
              <option value="ن">ن</option>
              <option value="ه">ه</option>
              <option value="و">و</option>
              <option value="ء">ء</option>
              <option value="ی">ی</option>
              <option value="ي">ي</option>
            </select>
            <h4>حرف الثانی</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <select
              name="third"
              value={third}
              onChange={(e) => {
                onChange(e);
              }}
            >
              <option value="0">Select </option>
              <option value="أ">أ</option>
              <option value="ا">ا</option>
              <option value="ب">ب</option>
              <option value="ت">ت</option>
              <option value="ث">ث</option>
              <option value="ج">ج</option>
              <option value="ح">ح</option>
              <option value="خ">خ</option>
              <option value="د">د</option>
              <option value="ذ">ذ</option>
              <option value="ر">ر</option>
              <option value="ز">ز</option>
              <option value="س">س</option>
              <option value="ش">ش</option>
              <option value="ص">ص</option>
              <option value="ض">ض</option>
              <option value="ط">ط</option>
              <option value="ظ">ظ</option>
              <option value="ع">ع</option>
              <option value="غ">غ</option>
              <option value="ف">ف</option>
              <option value="ق">ق</option>
              <option value="ك">ك</option>
              <option value="ل">ل</option>
              <option value="م">م</option>
              <option value="ن">ن</option>
              <option value="ه">ه</option>
              <option value="و">و</option>
              <option value="ء">ء</option>
              <option value="ی">ی</option>
              <option value="ي">ي</option>
            </select>
            <h4>حرف الثالث</h4>
          </div>
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            type="submit"
          >
            <span style={{ color: "white" }}>Search</span>{" "}
            {loading && (
              <LineWave
                height="25"
                width="25"
                color="#fff"
                ariaLabel="line-wave"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
              />
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Home;
