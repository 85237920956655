import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
function Detail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const viewtable = (id) => {
    navigate(`/view-detail/${id}`, { state: state });
  };
  return (
    <div className="container">
      <div className="search__inner">
        <h1>الصرف(Morphology)</h1>
        {state?.length > 0 ? (
          state?.map((item) => (
            <table key={item.id}>
              <tbody>
                <tr>
                  <th>Title</th>
                  <th
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ padding: "5px 10px" }}>Value</span>
                    <span className="button" onClick={() => viewtable(item.id)}>
                      Detail
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>جِذر</th>
                  <td>{item.result.eleven}</td>
                </tr>
                <tr>
                  <th>الباب</th>
                  <td>{item.result.nine}</td>
                </tr>
                <tr>
                  <th>Form</th>
                  <td>{item.result.ten}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{item.result.eight}</td>
                </tr>
              </tbody>
            </table>
          ))
        ) : (
          <h4>No Records Found</h4>
        )}
        <Link style={{ textDecoration: "none" }} className="button" to="/">
          Back
        </Link>
      </div>
    </div>
  );
}

export default Detail;
